import { axiosService } from '@services/axios'
import {MORPHEUS_URL} from '../../config'

const veryOldDate =  new Date(1900, 1, 1).toISOString().split("T")[0]
/* const yearInit = new Date(dateC.getFullYear(), 0, 1) */

export const getMessages = async (params = {page: 1, perPage: 10, filters: {}}) => {
  const {
    /* date: [init, end] */
    sent = false
  } = params?.filters
  const inity = params?.filters?.date?.[0]
  const endy = params?.filters?.date?.[1]
  const {
    page = 1,
    perPage = 10
  } = params
  try {
    const builtParams = {
      /* date_init: init ?? yearInit.toISOString().split("T")[0],
      date_end: end ?? new Date().toISOString().split("T")[0] */
    }

    if (sent) {
      builtParams["date_init"] = inity ? inity : veryOldDate
      builtParams["date_end"] = endy ? endy : new Date().toISOString().split("T")[0]
    }
    if (page && perPage) builtParams['skip'] = (page - 1) * perPage
    if (page) builtParams['limit'] = perPage

    const url = sent ? `${MORPHEUS_URL}/front/message/send` : `${MORPHEUS_URL}/front/notification/all`
    const ret = await axiosService.get(url, { params: builtParams })
    const response = ret.data
    
    const unreadRet = await axiosService.get(`${MORPHEUS_URL}/front/notification/not_read`)
    const unreadResponse = unreadRet.data

    const allData = (response?.all_data) ?? []
    //paginated no funciona, borrar cuando funcione bien
    const paginated = (response?.data) ?? []
    const compiledResponse = {
      data: paginated ?? allData,
      data_no_read: unreadResponse.data,
      allData,
      params,
      totalPages: allData.length,
      total_no_read: unreadResponse.total_not
    }
    return compiledResponse
  } catch (err) {
    return []
  }
}

export const getUnreadMessages = async () => {
  try {
    const unreadRet = await axiosService.get(`${MORPHEUS_URL}/front/notification/not_read`)
    const unreadResponse = unreadRet.data
    const compiledResponse = {
      data_no_read: unreadResponse.data,
      total_no_read: unreadResponse.total_not
    }
    
    return compiledResponse
  } catch (err) {
    return []
  }
}

export const getMessage = async ({msgId, sent = true}) => {
  try {
    const url = `${MORPHEUS_URL}/front/message/show/{msgId}`
    const response = await axiosService.get(url, {params:{msg_id: msgId}})
    
    if (!sent) {
      const body = { read_: true }
      await axiosService.put(`${MORPHEUS_URL}/front/notification/read?msg_id=${msgId}`, body)
    }

    return response.data
  } catch (err) {
    return []
  }
}

export const postMessages = async (params) => {
    const url = `${MORPHEUS_URL}/front/message/create`
    const response = await axiosService.post(url, params)
    return response.data
}

export const deleteMessages = async (msgId) => {
    const url = `${MORPHEUS_URL}/front/message/delete/{id}?mess_id=${msgId}`
    const response = await axiosService.delete(url)
    return response.data 
}

export const getUsers = async () => {
  try {
    const url = `${MORPHEUS_URL}/front/users/show`
    const response = await axiosService.get(url)
    return response.data 
  } catch (err) {
    return []
  }
}
