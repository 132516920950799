/* eslint-disable no-unused-vars */
// ** Axios Imports
import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const getLoans = async (params) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/takersRequest`,
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                period: params.filters.term ? params.filters.term : null,
                profile: params.filters.profile,
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })

    return {
        data: response.data.Paginated,
        allData: response.data.all_data,
        total: response.data.all_data.length,
        params
    }
}

export const getInvestments = async (params) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/investorsRequest`,
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                period: params.filters.term ? params.filters.term : null,
                profile: params.filters.profile,
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return {
        data: response.data.Paginated,
        allData: response.data.all_data,
        total: response.data.all_data.length,
        params
    }
}

export const createPackage = async (data) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/admin/create_package`, data)
    return response
}