import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRequests, putRequest} from '@services/myRequests'

export const getData = createAsyncThunk('myRequests/get', async params => {
    const response = await getRequests(params)
    return response
})

export const putRequests = createAsyncThunk('myRequests/post', async (operations, { dispatch, getState, rejectWithValue }) => {
    try {
        await putRequest(operations)
        const ret = await dispatch(getData(getState().myRequests.params))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const myRequestsSlice = createSlice({
    name: 'myRequests',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: { 
          filters: {
            date: ['', ''],
            operation: ''
          },
          page: 1,
          perPage: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default myRequestsSlice.reducer
