import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getQueries, readQueryMessage } from '@services/admin/manageQueries'

export const getData = createAsyncThunk('manageQueries/getData', async params => {
    const response = await getQueries(params)
    return response
})

export const readQuery = createAsyncThunk('manageQueries/readQuery', async (id, { dispatch, getState, rejectWithValue }) => {
    try {
        await readQueryMessage(id)
        await dispatch(getData(getState().manageQueries.params))
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})
export const manageQueriesSlice = createSlice({
    name: 'manageQueries',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: {}
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default manageQueriesSlice.reducer