import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { postPhoto, postRepPhoto } from '@services/user'

export const uploadPhoto = createAsyncThunk('photo/addPhoto', async (photo) => {
      const res = await postPhoto(photo)
      return res.data
  })

  export const uploadRepPhoto = createAsyncThunk('photo/addRepPhoto', async ({ photo, id }) => {
    const res = await postRepPhoto(photo, id)
    return res.data
  })


  export const photoSlice = createSlice({
    name: 'photo',
    initialState: {
      data: [],
      detail: {},
      total: 1,
      params: {},
      allData: []
    },
    extraReducers: builder => {
      builder.addCase(uploadPhoto.fulfilled, (state, action) => {
        state.data = action.payload
      })
      builder.addCase(uploadRepPhoto.fulfilled, (state, action) => {
        state.data = action.payload
      })
    }
  })

  export default photoSlice.reducer