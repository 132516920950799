/* eslint-disable no-unused-vars */
// ** Axios Imports
import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const getOperations = async (params) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/operation_admin/`,
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                type_operation: params.filters.operation ? params.filters.operation : null,
                user: params.filters.user ? params.filters.user : null,
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return {
        data: response.data.data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}
export const getIncomes = async (params) => {
    const url = `${MORPHEUS_URL}/admin/operation_admin/`
    const response = await axiosService.get(url, 
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                user: params.filters.user ? params.filters.user : null,
                state: params.filters.state ? params.filters.state : null,
                type_operation: 'Deposito de dinero',
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return  {
        data: response.data.data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}

export const getOutcomes = async (params) => {
    const url = `${MORPHEUS_URL}/admin/operation_admin/`
    const response = await axiosService.get(url, 
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                user: params.filters.user ? params.filters.user : null,
                state: params.filters.state ? params.filters.state : null,
                type_operation: 'Retiro de dinero',
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return  {
        data: response.data.data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}

export const confirmOperations = async (operations) => {
    const response = await axiosService.put(`${MORPHEUS_URL}/admin/operation_admin/`, operations)
    return response
}

export const postTransaction = async (params) => {
    const url = `${MORPHEUS_URL}/admin/operation_admin/depositMoney`
    const response = await axiosService.post(url, params)
    return response.data
  }

  export const deleteTransaction = async (op_id) => {
    const response = await axiosService.delete(`${MORPHEUS_URL}/admin/operation_admin/operation/${op_id}`)
    return response
  }

export const putTransaction = async (op_id, params) => {
    const response = await axiosService.put(`${MORPHEUS_URL}/admin/operation_admin/operation/${op_id}`, params)
    return response
  }