/* eslint-disable no-unused-vars */
// ** Axios Imports
import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const getQueries = async (params) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/manage_queries/`,
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return {
        data: response.data.data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}
export const getAllQueries = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/manage_queries/`)
    const data_no_read = response.data.data.filter(item => item.contact_read === false)
    const total_no_read = data_no_read.length
    return { data_no_read, total_no_read }

}
export const readQueryMessage = async (id) => {
    const response = await axiosService.put(`${MORPHEUS_URL}/admin/manage_queries/`, { contact_id: id })
    return response
}
