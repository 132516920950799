import { MORPHEUS_URL } from '../../config'
import { axiosService } from '../axios'

export const fetchReqUserUnsubscribe = async (type) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/unsubscribe_button/verify?type=${type}`)
    return response.data
}

export const postUserUnsubscribe = async (motivo) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/front/unsubscribe_button/unsubscribe?motivo=${motivo}`)
    return response.data
}

