// ** Axios Imports
import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from '../../config'
const FormData = require('form-data')
const formData = new FormData()

export const getPhysicalProfileData = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/profile/my_physical_profile`)
  return response.data
}

export const updatePhysicalProfile = async (profile) => {
  const response = await axiosService.put(`${MORPHEUS_URL}/profile/my_physical_profile`, profile)
  return response.data
}

export const getJuridicalProfileData = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/profile/my_juridical_profile`)
  return response.data
}

export const updateJuridicalProfile = async (profile) => {
  const response = await axiosService.put(`${MORPHEUS_URL}/profile/my_juridical_profile`, profile)
  return response.data
}

export const getBankAccounts = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/profile/bank_account_data`)
  return response.data
}


export const updateDataBankAccount = async (dataBankAccounts) => {
  const response = await axiosService.post(`${MORPHEUS_URL}/profile/bank_account_data`, dataBankAccounts)
  return response.data
}

export const deleteBankAccount = async (id) => {
  const response = await axiosService.delete(`${MORPHEUS_URL}/profile/bank_account_data?account_id=${id}`)
  return response
}

export const getRepresentatives = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/profile/my_juridical_profile/representative`)
  return response.data
}

export const updateRepresentative = async (dataRepresentatives) => {
  const response = await axiosService.post(`${MORPHEUS_URL}/profile/my_juridical_profile/representative`, dataRepresentatives)
  return response.data
}

export const getAuthorizedRepresentatives = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/profile/my_physical_profile/legal_authorized`)
  return response.data
}

export const updateAuthorizedRepresentative = async (dataAuthorizedRepresentatives) => {
  const response = await axiosService.post(`${MORPHEUS_URL}/front/authorizedLegal/`, dataAuthorizedRepresentatives)
  return response.data
}

export const uploadJuridicalFiles = async (file, filename) => {
  formData.append("file", file)
  const headers = { 'Content-Type': "multipart/form-data" }
  const response = await axiosService.post(`${MORPHEUS_URL}/profile/my_juridical_profile/upload_files?filename_in=${filename}`, formData, headers)
  return response
}

export const uploadProfileFiles = async (file, filename) => {
  formData.append('file', file)
  const headers = { 'Content-Type': "multipart/form-data" }
  const response = await axiosService.post(`${MORPHEUS_URL}/profile/my_physical_profile/upload_files?filename_in=${filename}`, formData, headers)
  return response
}

export const uploadRepresentativeFiles = async (file, repId, filename) => {
  formData.append("file", file)
  const headers = { 'Content-Type': "multipart/form-data" }
  const response = await axiosService.post(`${MORPHEUS_URL}/profile/my_juridical_profile/representative/upload_files?filename_in=${filename}&rep_id=${repId}`, formData, headers)
  return response
}

export const uploadAuthorizedFiles = async (file, repId, filename) => {
  formData.append("file", file)
  const headers = { 'Content-Type': "multipart/form-data" }
  const response = await axiosService.post(`${MORPHEUS_URL}/front/authorizedLegal/upload-files?filename_in=${filename}&aut_id=${repId}`, formData, headers)
  return response
}

export const deleteAuthRep = async (id) => {
  const response = await axiosService.delete(`${MORPHEUS_URL}/front/auth_rep/delete/${id}`)
  return response
}

export const getTerms = async (tip_sol) => {
  const response = await axiosService.get(`${MORPHEUS_URL}/front/request/term_card?tip_sol=${tip_sol}`)
  return response
}

export const getMoras = async (tip_sol) => {
  const response = await axiosService.get(`${MORPHEUS_URL}/front/request/mora_card?tip_sol=${tip_sol}`)
  return response
}

export const getCProfiles = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/front/request/credit_profiles_card`)
  return response.data
}
