// ** Axios Imports
import { MORPHEUS_URL } from "@src/config"
import { axiosService } from "../axios"
const FormData = require('form-data')
const formData = new FormData()

export const getPhoto = async (id) => {
  const response = await axiosService.get(`${MORPHEUS_URL}/photo/get_photo?id=${id}`)
  return response
}

export const postPhoto = async (file) => {
  formData.append('usr_photo', file)
  const headers = { 'Content-Type': "multipart/form-data" }
  const response = await axiosService.post(`${MORPHEUS_URL}/photo/upload_photo`, formData, headers)
  return response
}

export const postRepPhoto = async (file, id) => {
  formData.append('usr_photo', file)
  const headers = { 'Content-Type' : "multipart/form-data"}
  const response = await axiosService.post(`${MORPHEUS_URL}/photo/upload_photo?auth_rep_id=${id}`, formData, headers)
  return response 
}
