// ** Redux imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Services imports
import {
  getPhysicalProfileData, updatePhysicalProfile,
  getJuridicalProfileData, updateJuridicalProfile,
  getBankAccounts, updateDataBankAccount, deleteBankAccount,
  getRepresentatives, updateRepresentative,
  getAuthorizedRepresentatives, updateAuthorizedRepresentative,
  getTerms, getMoras, getCProfiles} from '@services/profiles'
import { deleteAuthRep } from '../../services/profiles'


export const getData = createAsyncThunk('profile/getData', async () => {
  const response = await getPhysicalProfileData()
  return response
})

export const editProfile = createAsyncThunk('profile/editProfile',
  async (profile, { dispatch, rejectWithValue }) => {
    try {
      await updatePhysicalProfile(profile)
      await dispatch(getData())
      return profile
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

export const getJuridicalData = createAsyncThunk('profile/getJuridicalData', async () => {
  const response = await getJuridicalProfileData()
  return response
})

export const editJuridicalProfile = createAsyncThunk('profile/editJuridicalProfile',
  async (dataJuridical, { rejectWithValue, dispatch }) => {
    try {
      await updateJuridicalProfile(dataJuridical)
      await dispatch(getJuridicalData())
      return dataJuridical
    } catch (err) {
      rejectWithValue(err.response)
    }
  })

export const getBankAccountsData = createAsyncThunk('profile/getBankAccountsData', async () => {
  const response = await getBankAccounts()
  return response
})

export const deleteBankAccountData = createAsyncThunk('profile/deleteBankAccountData', async (id, { dispatch }) => {
  const response = await deleteBankAccount(id)
  await dispatch(getBankAccountsData())
  return response
})

export const updateBankAccountsData = createAsyncThunk('profile/updateBankAccountsData',
  async (dataBankAccounts, { dispatch, rejectWithValue }) => {
    try {
      await updateDataBankAccount(dataBankAccounts)
      await dispatch(getBankAccountsData())
      return dataBankAccounts
    } catch (err) {
      return rejectWithValue(err.message)
    }
  })


export const getRepresentativeData = createAsyncThunk('profile/getRepresentativeData', async () => {
  const response = await getRepresentatives()
  return response
})

export const updateRepresentativeData = createAsyncThunk('profile/updateRepresentativeData',
  async (dataRepresentative, { rejectWithValue, dispatch }) => {
    try {
      const newData = await updateRepresentative(dataRepresentative)
      await dispatch(getRepresentativeData())
      return newData
    } catch (error) {
      rejectWithValue(error)
    }
  })

export const getAuthorizedData = createAsyncThunk('profile/getAuthorizedData', async () => {
  const response = await getAuthorizedRepresentatives()
  return response
})

export const updateAuthorizedData = createAsyncThunk('profile/updateAuthorizedData',
  async (dataAuthorized, { rejectWithValue, dispatch }) => {
    try {
      const newData = await updateAuthorizedRepresentative(dataAuthorized)
      await dispatch(getAuthorizedData())
      return newData
    } catch (error) {
      rejectWithValue(error)
    }
  })

export const deleteAuthRepData = createAsyncThunk('profile/deleteAuthRepData', async (id, { dispatch }) => {
  await deleteAuthRep(id)
  await dispatch(getAuthorizedData())
  await dispatch(getRepresentativeData())
}
)

export const getTermsData = createAsyncThunk('profile/getTermsData', async (tip_sol) => {
  const response = await getTerms(tip_sol)
  return response
})

export const getMorasData = createAsyncThunk('profile/getMorasData', async (tip_sol) => {
  const response = await getMoras(tip_sol)
  return response
})

export const getCProfilesData = createAsyncThunk('profile/getCProfilesData', async () => {
  const response = await getCProfiles()
  return response
})

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: null,
    repData: [],
    bankAccounts: []
  },
  reducers: {
    setDecJuradaCheckboxValue: (state, action) => {
      const { name, checked } = action.payload
      state.data[name] = checked
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getJuridicalData.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(editJuridicalProfile.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getBankAccountsData.fulfilled, (state, action) => {
        state.bankAccounts = action.payload
      })
      .addCase(updateBankAccountsData.fulfilled, (state, action) => {
        state.bankAccounts = action.payload
      })
      .addCase(deleteBankAccountData.fulfilled, (state, action) => {
        state.bankAccounts = action.payload
      })
      .addCase(getRepresentativeData.fulfilled, (state, action) => {
        state.repData = action.payload
      })
      .addCase(getAuthorizedData.fulfilled, (state, action) => {
        state.repData = action.payload
      })
      .addCase(getTermsData.fulfilled, (state, action) => {
        state.terms = action.payload
      })
      .addCase(getMorasData.fulfilled, (state, action) => {
        state.moras = action.payload
      })
    
  }
})

export const { setDecJuradaCheckboxValue } = profileSlice.actions

export default profileSlice.reducer