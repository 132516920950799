import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const updatePassword = async (data) => {
    const response = await axiosService.put(`${MORPHEUS_URL}/front/account/change_password`, data)
    return response.data
  }

export const updateEnableAccount = async (flag) => {
    const response = await axiosService.put(`${MORPHEUS_URL}/front/account/deactivate`, flag)
    return response.data
  }

  export const getRecentSessions = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/sessionRegister/get_session_register`)

    return response.data
  }

  export const getRecentDevices = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    let osType = "Desconocido"
    let deviceType = "Desconocido"
    let browserName = "Desconocido"
  
    const userAgentData = navigator.userAgentData || {}
    const INFO_NECESARIA = ['Google Chrome', 'Firefox', 'Safari', 'Opera', 'Microsoft Edge', 'Samsung']
  
    // Detectar el navegador
    if (userAgentData.brands && userAgentData.brands.length > 0) {
      userAgentData.brands.forEach((brand) => {
        const brandName = brand.brand
        if (INFO_NECESARIA.includes(brandName)) {
          browserName = brandName
        }
      })
    } else {
      if (userAgent.includes("chrome")) browserName = "Google Chrome"
      else if (userAgent.includes("firefox")) browserName = "Firefox"
      else if (userAgent.includes("safari")) browserName = "Safari"
      // Agrega más navegadores si es necesario
    }
  
    // Detectar sistema operativo y tipo de dispositivo
    if (userAgent.includes("windows")) {
      osType = "Windows"
      deviceType = "PC"
    } else if (userAgent.includes("mac os")) {
      osType = "macOS"
      deviceType = "Mac"
    } else if (userAgent.includes("linux")) {
      osType = "Linux"
      deviceType = "PC"
    } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
      osType = "iOS"
      deviceType = "iPhone o iPad"
    } else if (userAgent.includes("android")) {
      osType = "Android"
      deviceType = "Dispositivo Android"
    } else if (userAgent.includes("blackberry")) {
      osType = "BlackBerry"
      deviceType = "Dispositivo BlackBerry"
    } else if (userAgent.includes("windows phone")) {
      osType = "Windows Phone"
      deviceType = "Windows Phone"
    } else if (userAgent.includes("smarttv")) {
      osType = "Smart TV"
      deviceType = "Smart TV"
    } else if (userAgent.includes("xbox")) {
      osType = "Xbox"
      deviceType = "Xbox"
    } else if (userAgent.includes("playstation")) {
      osType = "PlayStation"
      deviceType = "PlayStation"
    }
  
    return fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const deviceLocation = data.country_name || 'Desconocido'
        return {
          browser: browserName,
          operative_system: osType,
          device: deviceType,
          location: deviceLocation
        }
      })
      .catch(error => {
        console.error('Error obteniendo la ubicación:', error)
        return {
          browser: browserName,
          operative_system: osType,
          device: deviceType,
          location: 'Desconocido'
        }
      })
  }