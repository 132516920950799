/* eslint-disable no-unused-vars */
// ** Axios Imports
import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const getOperations = async (params) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/adjustment_operations/`,
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                user: params.filters.user ? params.filters.user : null,
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return {
        data: response.data.data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}

export const newOperation = async (operation) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/admin/adjustment_operations/`, operation)
    return response
}
