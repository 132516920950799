/**
 * URL consts
 */

export const MORPHEUS_PROD_URL = "https://back.morpheuscapital.com.ar"

// Development URLs

export const MORPHEUS_DEV_URL = "http://localhost:8002"

export const MORPHEUS_URL = process.env.NODE_ENV === 'production' ? MORPHEUS_PROD_URL : MORPHEUS_DEV_URL