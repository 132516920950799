import { AbilityBuilder } from "@casl/ability"

export const defineRulesFor = (role) => {
    const { can, rules } = new AbilityBuilder()
    can('access', 'freeResource')
    if (role === 'admin') {
        can('access', 'admin')
    }
    if (role === 'front') {
        can('access', 'frontGuestResource')
        can('access', 'front')
    }
    if (role === 'guest') {
        can('access', 'frontGuestResource')
        can('access', 'guest')
    }
    return rules
}