import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getOperations } from '@services/myOperations'

export const getData = createAsyncThunk('myOperations', async params => {
    const response = await getOperations(params)
    return response
})

export const myOperationsSlice = createSlice({
    name: 'myOperations',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: {
            page: 1,
            perPage: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default myOperationsSlice.reducer