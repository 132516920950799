import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPackageDetails } from '@src/services/seePackage'

export const getData = createAsyncThunk('packageDetails', async params => {
    const response = await getPackageDetails(params)
    return response
})

export const packageDetailsSlice = createSlice({
    name: 'adminMessages',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: {
          paq_id: '',
          filters: {
            usrLogin: ''
          },
          page: 1,
          perPage: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default packageDetailsSlice.reducer
