import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { newCard, fetchCards, putCard, deleteCardGuarantee, postDocument } from '../../../services/profiles/guarantee'

/// ** CARDS ** ///
export const postCard = createAsyncThunk('guarantee/postCard', async (data) => {
    const response = await newCard(data)
    return response.data
})

export const getCards = createAsyncThunk('guarantee/getCards', async () => {
    const response = await fetchCards()
    return response
})

export const editCard = createAsyncThunk('guarantee/editCard', async (data) => {
    const response = await putCard(data)
    return response.data
})

export const deleteCard = createAsyncThunk('guarantee/deleteCard', async (card_id, {dispatch, getState}) => {
    await deleteCardGuarantee(card_id)
    const { cards } = getState().guarantee
    dispatch(getCards(cards))
    return card_id
})

// ** DOCUMENTS ** //

export const addDocument = createAsyncThunk('guarantee/addDocument', async (data) => {
    const response = await postDocument(data)
    return response.data
})

const guaranteeSlice = createSlice({
    name: 'guarantee',
    initialState: {
        data: [],
        params: {},
        cards: [],
        documents: []
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(postCard.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(getCards.fulfilled, (state, action) => {
            state.cards = action.payload
        })
        builder.addCase(deleteCard.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(editCard.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(addDocument.fulfilled, (state, action) => {
            state.documents = action.payload
        })
    }}
)


export const { reducer: guaranteeReducer } = guaranteeSlice