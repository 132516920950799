import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from '../../config'

const transaction = {
  default: () => { },
  in: async ({ accId, amount }) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/front/operation/depositMoney`, {
      account_id: accId,
      amount
    },
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }

      })

    const { data } = response
    return data
  },
  out: async ({ accId, amount }) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/front/operation/withdrawMoney/`, {
      account_id: accId,
      amount
    },
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    return response
  }
}

export async function getCards() {
  const response = await axiosService.get(`${MORPHEUS_URL}/front/bankaccount`)
  const { data } = response
  return data

}

export async function setTransaction({ type, amount, account }) {
  const finalAmount = Number(amount.replace(/[$,]*/g, ""))
  return transaction[type]({ usrId: 1, accId: account.account_id, amount: finalAmount })

}

export async function getBalance() {
  const response = await axiosService.get(`${MORPHEUS_URL}/front/balance`)
  const { data } = response
  return {
    balance: data.balance,
    pendingBalance: data.pending_balance
  }
}

export async function sendBankData() {
  const response = await axiosService.post(`${MORPHEUS_URL}/front/operation_front/send_bank_details`)
  return response
}