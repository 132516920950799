import { axiosService } from '@services/axios'
import { MORPHEUS_URL } from '../../config'

// const dateC = new Date()
// const yearInit = new Date(dateC.getFullYear(), 0, 1)

export const getRequests = async (params) => {
  const {
    filters: {
      date: [init = null, end = null],
      operation = '',
      sort = "desc"
    },
    page = 1,
    perPage = 10
  } = params
  try {

    const builtParams = {}
    if (init) builtParams['date_init'] = init
    if (end) builtParams['date_end'] = end


    if (operation) builtParams['filter_sol'] = operation
    if (page && perPage) builtParams['skip'] = (page - 1) * perPage
    if (perPage) builtParams['limit'] = perPage
    if (sort) builtParams['sort_order'] = sort

    const url = `${MORPHEUS_URL}/front/request`
    const ret = await axiosService.get(url, { params: builtParams })
    const response = ret.data

    const allData = (response?.all_data) ?? []
    const paginated = (response?.Paginated) ?? []
    const compiledResponse = {
      data: paginated ?? allData,
      allData,
      params,
      totalPages: allData.length
    }

    return compiledResponse
  } catch (err) {
    return []
  }
}

export const putRequest = async (params) => {
  // try {
  const url = `${MORPHEUS_URL}/front/request/`
  const response = await axiosService.put(url, params)
  return response.data
  // } catch (err) {
  //   console.log(err)
  //   return err
  // }
}

export const getRescuesRequest = async (params) => {
  const {
    filters: {
      date: [init = null, end = null],
      operation = '',
      sort = "desc"
    },
    page = 1,
    perPage = 10
  } = params


  const builtParams = {}
  if (init) builtParams['date_init'] = init
  if (end) builtParams['date_end'] = end
  if (operation) builtParams['filter_sol'] = operation
  if (page && perPage) builtParams['skip'] = (page - 1) * perPage
  if (perPage) builtParams['limit'] = perPage
  if (sort) builtParams['sort_order'] = sort

  const url = `${MORPHEUS_URL}/front/request/rescue`

  const response = await axiosService.get(url, { params: builtParams })


  return response.data


}

export const editRescueRequest = async (data) => {
  const url = `${MORPHEUS_URL}/front/rescue/rescue_update`
    const res = await axiosService.put(url, data)
    return res.data
}