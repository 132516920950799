import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchGraphicRescues, createRescues, fetchRescues, fetchInvestmentsMarket} from "../../services/rescues"
import { editRescueRequest, getRescuesRequest } from "../../services/myRequests"

export const getGraphicRescues = createAsyncThunk('rescues/graphics', async () => {
  const response = await fetchGraphicRescues()
  return response
})

export const getRescues = createAsyncThunk('rescues/getall', async params => {
  const response = await fetchRescues(params)
  return response
})

export const postRescues = createAsyncThunk('rescues/postRescues', async (data, { dispatch, getState, rejectWithValue}) => {
  try {
    await createRescues(data)
    const ret = await dispatch(getRescues(getState().rescues.data))
    return ret
  } catch (error) {
    return rejectWithValue(error?.response?.data?.detail)
  }
})

export const getInvestmentsMarket = createAsyncThunk('rescues/investment', async (filters) => {
  const response = await fetchInvestmentsMarket(filters)
  return response
})

export const getRescuesForRequests = createAsyncThunk('rescues/request', async (params) => {


  const response = await getRescuesRequest(params)
  return response
})

export const editRescuesForRequests = createAsyncThunk('rescues/edit', async (data, {rejectWithValue}) => {
 try {
  const res = await editRescueRequest(data)
  return res
 } catch (error) {  
   return rejectWithValue(error?.response?.data?.detail)
 }
})

export const rescuesSlice = createSlice({
  name: "rescues", 
  initialState: {
    allData: [],
    data: [],
    total: 0,
    params: {}
  },
  reducer: {},
  extraReducers: builder => {
    builder
    .addCase(getGraphicRescues.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
    .addCase(getRescues.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(postRescues.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
    .addCase(getInvestmentsMarket.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
    // .addCase(selectTerm.fulfilled, (state, action) => {
    //   state.data = action.payload.data
    // })
    // .addCase(selectProfile.fulfilled, (state, action) => {
    //   state.data = action.payload.data
    // })
    .addCase(getRescuesForRequests.fulfilled, (state, action) => {
      console.log(action.payload)
    
      state.data = action.payload.Paginated || []
      state.allData = action.payload.all_data || []
      state.params = action.payload.params || {}
      state.total = action.payload.totalPages || 0

    })
  }
})

export default rescuesSlice.reducer