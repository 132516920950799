// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import confirmOperations from "./admin/confirmOperations"
import manageRequests from './admin/manageRequests'
import adminMessages from './admin/adminMessages'
import viewPackages from './admin/viewPackages'
import moneyManag from "./moneyManagement"
import auth from "./auth"
import validateProfiles from "./admin/validateProfiles"
import profile from "./profile"
import myoperations from "./myoperations"
import myRequests from "./myRequests"
import variables from './variablesManagement/index'
import packageDetails from "./admin/packageDetails"
import frontMessages from "./frontMessages"
import investments from "./myInvestments"
import loans from "./myLoans"
import addOperations from "./admin/addOperations"
import manageQueries from "./admin/manageQueries"
import communityBInquiries from "./admin/communityBInquiries"
import rescues from "./Rescues"
import photo from "./user"
import unsubscribeButton from "./unsubscribeButton"

const rootReducer = {
  auth,
  navbar,
  layout,
  validateProfiles,
  profile,
  moneyManag,
  confirmOperations,
  myoperations,
  myRequests,
  variables,
  manageRequests,
  adminMessages,
  packageDetails,
  viewPackages,
  frontMessages,
  investments,
  loans,
  addOperations,
  manageQueries,
  communityBInquiries,
  photo,
  rescues,
  unsubscribeButton
}

export default rootReducer