import { createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import { fetchReqUserUnsubscribe, postUserUnsubscribe } from "../../services/unsubscribeButton"

export const getReqUserUnsubscribe = createAsyncThunk('unsubscribeButton/getReqUserUnsubscribe', async (type) => {
    const response = await fetchReqUserUnsubscribe(type)
    return response
})

export const postUnsubscribe = createAsyncThunk('unsubscribeButton/postUnsubscribe', async (motivo) => {
    const response = await postUserUnsubscribe(motivo)
    return response
})


export const unsubscribeButtonSlice = createSlice({
    name: "unsubscribeButton",
    initialState: {
        reqUser: {},
        data: [],
        total: 0,
        params: {}
    },
    reducers: {}, 
    extraReducers: builder => {
        builder
        .addCase(getReqUserUnsubscribe.fulfilled, (state, action) => {
            state.reqUser = action.payload.data
        })
    }
})

export default unsubscribeButtonSlice.reducer