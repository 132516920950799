import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProfiles,
  getPhysicalProfile,
  getJuridicalProfile,
  validatePProfile,
  validateJProfile,
  validateRepresentative,
  validateAuthorized,
  getProfilesBy
} from '@services/admin/validateProfiles'

export const getData = createAsyncThunk('validateProfiles/getProfilesData', async () => {
  const response = await getProfiles()

  return response
})

export const getFilteredData = createAsyncThunk('validateProfiles/getProfilesDataFiltered', async (filters) => {
  const response = await getProfilesBy(filters)
  return {
    response,
    filters
  }
})

export const getPProfile = createAsyncThunk('validateProfiles/getPProfile', async id => {
  const response = await getPhysicalProfile(id)
  return response
})

export const editPProfile = createAsyncThunk('validateProfiles/editPProfile',
  async (validations, { dispatch, rejectWithValue }) => {
    try {
      await validatePProfile(validations)
      await dispatch(getPProfile(validations.id))
      return validations
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const getJProfile = createAsyncThunk('validateProfiles/getJProfile', async id => {
  const response = await getJuridicalProfile(id)
  return response
})

export const editJProfile = createAsyncThunk('validateProfiles/editJProfile',
  async (validations, { dispatch, rejectWithValue }) => {
    try {
      await validateJProfile(validations)
      await dispatch(getJProfile(validations.id))
      return validations
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const editRepresentative = createAsyncThunk('validateProfiles/editRepresentative',
  async (validations, { rejectWithValue }) => {
    try {
      await validateRepresentative(validations)
      return validations
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const editAuthorized = createAsyncThunk('validateProfiles/editAuthorized',
  async (validations, { rejectWithValue }) => {
    try {
      await validateAuthorized(validations)
      return validations
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const validateProfilesSlice = createSlice({
  name: 'validateProfiles',
  initialState: {
    data: [],
    allData: [],
    params: {
      filters: {
        skip: 0,
        limit: 0,
        profile_type: null,
        email: '',
        state_: null
      }
    },
    total: 0,
    profile: {
      data: null,
      bankAccounts: [],
      representatives: []
    }
  },
  reducers: {
    setProfileCheckboxValue: (state, action) => {
      const { name, checked } = action.payload
      state.profile.data[name] = checked
    },
    setRepresentativeCheckboxValue: (state, action) => {
      const { index, item, checked } = action.payload
      state.profile.representatives[index][item] = checked
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.total
        state.allData = action.payload.allData
        
      })
      .addCase(getPProfile.fulfilled, (state, action) => {
        state.profile.data = action.payload.profile
        state.profile.bankAccounts = action.payload.bankAccounts
        state.profile.representatives = action.payload.authorized
      })
      .addCase(getJProfile.fulfilled, (state, action) => {
        state.profile.data = action.payload.profile
        state.profile.bankAccounts = action.payload.bankAccounts
        state.profile.representatives = action.payload.representatives
      })
      .addCase(getFilteredData.fulfilled, (state, action) => {
        const { response, filters } = action.payload
        state.data = response.data
        state.total = response.total
        state.allData = response.allData
        state.params.filters = filters
      })
  }
})

export const { setProfileCheckboxValue, setRepresentativeCheckboxValue } = validateProfilesSlice.actions

export default validateProfilesSlice.reducer