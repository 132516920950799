import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const newCard = async (data) => { 
    const response = await axiosService.post(`${MORPHEUS_URL}/front/account/credit_card`, data)
    return response.data
}

export const fetchCards = async () => { 
    const response = await axiosService.get(`${MORPHEUS_URL}/front/account/credit_card`)
    return response.data
}

export const putCard = async (card_id, data) => { 
    const response = await axiosService.put(`${MORPHEUS_URL}/front/account/credit_card/${card_id}`, data)
    return response.data
}

export const deleteCardGuarantee = async (card_id) => { 
    const response = await axiosService.delete(`${MORPHEUS_URL}/front/account/credit_card/${card_id}`)
    return response.data
}

export const postDocument = async (data) => { 
    const headers = {'Content-Type': 'multipart/form-data'}
    const response = await axiosService.post(`${MORPHEUS_URL}/front/account/warranty`, data, headers)
    return response.data
}