import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getOperations, newOperation } from '@services/admin/addOperations'

export const getData = createAsyncThunk('addOperations/getOperationsData', async params => {
    const response = await getOperations(params)
    return response
})

export const addOperation = createAsyncThunk('addOperations/addOperation', async (operation, { dispatch, getState, rejectWithValue }) => {
    try {
        await newOperation(operation)
        await dispatch(getData(getState().addOperations.params))
        return operation
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const addOperationsSlice = createSlice({
    name: 'addOperations',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: {}
    },
    reducers: {
        clearData: (state) => {
            state.allData = []
            state.data = []
            state.total = 0
            state.params = {}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export const { clearData } = addOperationsSlice.actions

export default addOperationsSlice.reducer