import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getMessage, getUnreadMessages, getMessages, deleteMessages, postMessages} from '@services/frontMessages'

export const getUnread = createAsyncThunk('frontMessages/getUnread', async () => {
    const response = await getUnreadMessages()
    return response
})

export const getData = createAsyncThunk('frontMessages/get', async params => {
    const response = await getMessages(params)
    return response
})

export const getOne = createAsyncThunk('frontMessages/getOne', async id => {
    const response = await getMessage(id)
    return response
})

export const deleteMessage = createAsyncThunk('frontMessages/delete', async (id, { dispatch, getState, rejectWithValue }) => {
    try {
        await deleteMessages(id)
        const ret = await dispatch(getData(getState().adminMessages.params))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const postMessage = createAsyncThunk('frontMessages/post', async (msgData, { dispatch, getState, rejectWithValue }) => {
    try {
        await postMessages(msgData)
        const ret = await dispatch(getData(getState().adminMessages.params))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const resetSent = createAsyncThunk('adminMessages/resetSent', async (sent, { dispatch, getState, rejectWithValue }) => {
    try {
        const ret = await dispatch(getData({
                ...getState().adminMessages.params,
                filters:{...getState().adminMessages.params.filters, sent}
            }))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const frontMessagesSlice = createSlice({
    name: 'myRequests',
    initialState: {
        modalOpen: null, /*dos tipos, unRead, all */
        data: [],
        data_no_read: [],
        allData: [],
        totalPages: 0,
        total_no_read: 0,
        params: { 
          filters: {
            date: ['', '']
          },
          page: 1,
          perPage: 10
        },
        messageDetail: {}
    },
    reducers: {
        clearAll: state => ({
            ...state,
            modalOpen: null
        }),
        continueToNotification: state => ({
            ...state,
            modalOpen: 'unRead'
        }),
        continueToAll: state => ({
            ...state,
            modalOpen: 'all'
        }),
        clearDetail : state => ({
            ...state,
            messageDetail: {}
        })
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.sort((a, b) => -((+b.read) - (+a.read)))
                state.data_no_read = action.payload.data_no_read
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.totalPages = action.payload.totalPages
                state.total_no_read = action.payload.total_no_read
            })
            .addCase(getOne.fulfilled, (state, action) => {
                state.messageDetail = action.payload
            })
            .addCase(getUnread.fulfilled, (state, action) => {
                state.total_no_read = action.payload.total_no_read
                state.data_no_read = action.payload.data_no_read
            })
    }
})

export const {clearAll, continueToAll, clearDetail, continueToNotification} = frontMessagesSlice.actions
export default frontMessagesSlice.reducer
