import { axiosService } from '@services/axios'
import { MORPHEUS_URL } from '@src/config'

const veryOldDate = new Date(1900, 1, 1).toISOString().split("T")[0]

export const getPackages = async (params) => {
  const {
    filters: {
      date: end = null, //[init = null, end = null],
      login = ''
    },
    page = 1,
    perPage = 10
  } = params
  try {

    const builtParams = {
      fecha_inicio:  veryOldDate, //init ?? veryOldDate
      fecha_fin: end ?? new Date().toISOString().split("T")[0]
    }
    if (login) builtParams['login'] = login
    if (page && perPage) builtParams['skip'] = (page - 1) * perPage
    if (perPage) builtParams['limit'] = perPage

    const url = `${MORPHEUS_URL}/admin/getPackages`
    const ret = await axiosService.get(url, { params: builtParams })
    const response = ret.data
    const allData = (response?.all_data) ?? []
    const paginated = (response?.data) ?? []
    const compiledResponse = {
      data: paginated ?? allData,
      allData,
      params,
      totalPages: allData.length
    }

    return compiledResponse
  } catch (err) {
    return []
  }
}
export const getUsers = async () => {
  try {
    const url = `${MORPHEUS_URL}/admin/users/show`
    const response = await axiosService.get(url)
    return response.data
  } catch (err) {
    return []
  }
}