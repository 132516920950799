import { axiosService } from '../axios'

import { MORPHEUS_URL } from '../../config'

export const getLoans = async (fecha_i) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/dashboard/myLoans?fecha_i=${fecha_i}`)
    return response.data
}
export const getBalance = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/balance`)
    return response.data
}

export const pagarCuota = async (monto) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/front/operation/pay-installment?monto=${monto}`)
    return response.data
  }  
  

export const getLoansDetail = async (type, skip, limit, mora) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/dashboard/detail-account?type=${type}&skip=${skip}&limit=${limit}&mora=${mora}`)
    return response.data
}

export const loansDetailFilters = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/dashboard/detail-account-filter`)
    return response.data
}