import { axiosService } from '@services/axios'

import { MORPHEUS_URL } from '../../config'

export const getCBQueries = async (params) => {
    const url = `${MORPHEUS_URL}/admin/manage_b_comunidad_queries/`
    const response = await axiosService.get(url,
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return {
        data: response.data.data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}
export const getAllCBQueries = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/manage_b_comunidad_queries/`)
    const data_no_read = response.data.data.filter(item => item.bcomunidad_read === false)
    const total_no_read = data_no_read.length
    return { data_no_read, total_no_read }

}
export const putCBQueries = async (params) => {
    try {
        const url = `${MORPHEUS_URL}/admin/manage_b_comunidad_queries/`
        const response = await axiosService.put(url, params)
        return response.data
    } catch (err) {
        return []
    }
}