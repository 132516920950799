import { axiosService } from '@services/axios'

import {MORPHEUS_URL} from '../../config'

export const getOperations = async (params) => {
    const url = `${MORPHEUS_URL}/front/operation_front/`
    const response = await axiosService.get(url, 
        {
            params: {
                date_init: params.filters.date[0],
                date_end: params.filters.date[1],
                operation_type: params.filters.operation ? params.filters.operation : null,
                limit: params.perPage,
                skip: (params.page - 1) * params.perPage
            }
        })
    return  {
        data: response.data.all_data,
        allData: response.data.all_data,
        totalPages: response.data.total_records,
        params
    }
}