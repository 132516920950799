import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getLoans, getLoansDetail, loansDetailFilters } from "../../services/myLoans"

const currentDate = new Date()
  const year = currentDate.getFullYear()
  const day = currentDate.getDate().toString().padStart(2, '0')
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

export const fetchLoans = createAsyncThunk('front/myLoans', async () => {
  const response = await getLoans(formattedDate)
  return response
})

export const fetchLoansDetail = createAsyncThunk(
  'front/loans',
  async ({ type, skip, limit, mora }) => {
      const response = await getLoansDetail(type, skip, limit, mora)
      return response
  }
)

export const fetchLoansDetailFilters = createAsyncThunk('front/loans/filters', async () => {
  const response = await loansDetailFilters()
  return response
  }
)

export const loansSlice = createSlice({
  name: "loans", 
  initialState: {
    data: [],
    detail: [],
    filters: {
      profiles: [],
      terms: []
    },
    totalPages: 0
  },
  reducers: {
    clearLoans: (state) => {
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoans.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(fetchLoansDetail.fulfilled, (state, action) => {
        const detailWithoutTotal = action.payload.filter(item => !item.hasOwnProperty('total'))
      
        const totalPages = action.payload.find(item => item.hasOwnProperty('total'))?.total || 0

        state.detail = detailWithoutTotal
        state.totalPages = totalPages
      })
      .addCase(fetchLoansDetailFilters.fulfilled, (state, action) => {
        const profiles = action.payload.filter(item => item.perfil).map(item => item.perfil)
        const terms = action.payload.filter(item => item.plazo).map(item => item.plazo)
        state.filters.profiles = profiles
        state.filters.terms = terms
      })
  }
})
export const { clearLoans } = loansSlice.actions
export default loansSlice.reducer