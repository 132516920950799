import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCBQueries, putCBQueries} from '@services/communityBInquiries'

export const getData = createAsyncThunk('communityBInquiries/get', async params => {
    const response = await getCBQueries(params)
    return response
})

export const putRequests = createAsyncThunk('communityBInquiries/post', async (operations, { dispatch, getState, rejectWithValue }) => {
    try {
        await putCBQueries(operations)
        const ret = await dispatch(getData(getState().communityBInquiries.params))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const communityBInquiries = createSlice({
    name: 'communityBInquiries',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: { 
          filters: {
            date: ['', '']
          },
          page: 1,
          perPage: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default communityBInquiries.reducer
