import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getOperations, confirmOperations, postTransaction, getIncomes, getOutcomes, deleteTransaction, putTransaction } from '@services/admin/confirmOperations'

export const getData = createAsyncThunk('confirmOperations/getOperationsData', async params => {
    const response = await getOperations(params)
    return response
})
export const getIncomesData = createAsyncThunk('confirmOperations/getIncomesData', async params => {
    const response = await getIncomes(params)
    return response
})
export const getOutcomesData = createAsyncThunk('confirmOperations/getOutcomesData', async params => {
    const response = await getOutcomes(params)
    return response
})
export const approveOperations = createAsyncThunk('confirmOperations/approveOperations', async ({ operations, type_operation }, { dispatch, getState, rejectWithValue }) => {
    try {
        await confirmOperations(operations)
        if (type_operation === 'income') await dispatch(getIncomesData(getState().confirmOperations.incomes.params))
        if (type_operation === 'outcome') await dispatch(getOutcomesData(getState().confirmOperations.outcomes.params))
        return operations
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const addTransaction = createAsyncThunk('confirmOperations/addTransaction', async (transaction, { dispatch, getState, rejectWithValue }) => {
    try {
        await postTransaction(transaction)
        const ret = await dispatch(getIncomesData(getState().confirmOperations.incomes.params))
        return { ret }
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const removeTransaction = createAsyncThunk('confirmOperations/removeTransaction', async (op_id, { dispatch, getState, rejectWithValue }) => {
    try {
        await deleteTransaction(op_id)
        const ret = await dispatch(getIncomesData(getState().confirmOperations.incomes.params))
        return { ret }
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const editTransaction = createAsyncThunk('confirmOperations/editTransaction', async ({ op_id, transaction }, { dispatch, getState, rejectWithValue }) => {
    try {
        await putTransaction(op_id, transaction)
        const ret = await dispatch(getIncomesData(getState().confirmOperations.incomes.params))
        return { ret }
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const confirmOperationsSlice = createSlice({
    name: 'confirmOperations',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: {},
        incomes: {
            allData: [],
            data: [],
            total: 0,
            params: {}
        },
        outcomes: {
            allData: [],
            data: [],
            total: 0,
            params: {}
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getIncomesData.fulfilled, (state, action) => {
                state.incomes.data = action.payload.data
                state.incomes.allData = action.payload.allData
                state.incomes.params = action.payload.params
                state.incomes.total = action.payload.totalPages
            })
            .addCase(getOutcomesData.fulfilled, (state, action) => {
                state.outcomes.data = action.payload.data
                state.outcomes.allData = action.payload.allData
                state.outcomes.params = action.payload.params
                state.outcomes.total = action.payload.totalPages
            })
            .addCase(removeTransaction.fulfilled, (state, action) => {
                state.incomes.data = action.payload.ret.data
                state.incomes.allData = action.payload.ret.allData
                state.incomes.params = action.payload.ret.params
                state.incomes.total = action.payload.ret.totalPages
            })
           
    }
})

export default confirmOperationsSlice.reducer