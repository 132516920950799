// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getVariablesManagment, postVariablesManagment } from "../../services/variablesManagement"

// // ** Axios Imports
// import axios from "axios"

// export const getBookmarks = createAsyncThunk(
//   "layout/getBookmarks",
//   async () => {
//     const response = await axios.get("/api/bookmarks/data")
//     return {
//       data: response.data.suggestions,
//       bookmarks: response.data.bookmarks
//     }
//   }
// )

// export const updateBookmarked = createAsyncThunk(
//   "layout/updateBookmarked",
//   async (id) => {
//     await axios.post("/api/bookmarks/update", { id })
//     return id
//   }
// )

export const getData = createAsyncThunk('variablesManagement', async (origin) => {
  const response = await getVariablesManagment(origin)
  return response
})

export const postData = createAsyncThunk('variablesManagementPost', async ({ data, to }, { rejectWithValue }) => {
  try {
    const response = await postVariablesManagment({ data, to })
    return response
  } catch (err) {
    const { detail } = err?.response?.data
    const CAMPOS = {

      tasa_real: "Tasa Real",
      inflacion_esperada: "Inflación Esperada",
      depr_esperada: "Depreciación Esperada",
      tasa_inversor: "Tasa Inversor",
      tasa_tomador: "Tasa Tomador",

      tasa_interes: 'Tasa de Interés del Sistema Financiero',
      precio_esperada: 'Tasa de Variación Cambiaria Esperada',
      cambiaria_espe: 'Tasa de Variación de Índice de Precios Efectiva Esperada',
      tasa_int_inversor: 'Tasa de interés inversor',
      tasa_int_tomador: 'Tasa de interés tomador',
      servicio_6: 'Servicio prestado por el inversor para 6 meses:',
      servicio_12: 'Servicio prestado por el inversor para 12 meses:',
      servicio_18: 'Servicio prestado por el inversor para 18 meses:',
      prob_incum_A: 'P.I para un perfil A',
      prob_incum_B: 'P.I para un perfil B',
      prob_incum_C: 'P.I para un perfil C'
    }
    const MENSAJES = {
      'field required': 'el campo es requerido',
      'ensure this value is less than or equal to 1': 'El valor ingresado debe estar entre 0% y 100%'
    }
    const msg = {
      422: detail.map(item => `En ${CAMPOS[item.loc[1]]} ${MENSAJES[item.msg]}`).join(', \n ')
    }
    return rejectWithValue(msg[err?.response?.status])
  }
})

export const variablesSlice = createSlice({
  name: "variables",
  initialState: {
    'variables base': [],
    'tasa bancos': [],
    'probabilidad de incumplimiento': [],
    data: []
  },
  reducers: {
    create: (state, action) => {
      const { payload } = action
      state[payload.stateName].push(payload.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        const { origin, data } = action.payload
        state[origin] = data
      })
    /*       .addCase(postData.fulfilled, (state, action) => {
    
          }) */
  }
  //   ,
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(getBookmarks.fulfilled, (state, action) => {
  //         state.suggestions = action.payload.data
  //         state.bookmarks = action.payload.bookmarks
  //       })
  //       .addCase(updateBookmarked.fulfilled, (state, action) => {
  //         let objectToUpdate

  //         // ** find & update object
  //         state.suggestions.find((item) => {
  //           if (item.id === action.payload) {
  //             item.isBookmarked = !item.isBookmarked
  //             objectToUpdate = item
  //           }
  //         })

  //         // ** Get index to add or remove bookmark from array
  //         const bookmarkIndex = state.bookmarks.findIndex(
  //           (x) => x.id === action.payload
  //         )

  //         if (bookmarkIndex === -1) {
  //           state.bookmarks.push(objectToUpdate)
  //         } else {
  //           state.bookmarks.splice(bookmarkIndex, 1)
  //         }
  //       })
  //   }
})

// export const { handleSearchQuery } = layoutSlice.actions

export default variablesSlice.reducer
export const { create } = variablesSlice.actions

