import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
//** Services import */
import { getCards, setTransaction, getBalance } from '../../services/moneyManagement'

export const getDataCards = createAsyncThunk('buyerJourney/getDataCards', async () => {
  const response = await getCards()
  return response
})

export const getBalanceFor = createAsyncThunk('buyerJourney/getBalance', async () => {
  const response = await getBalance()
  return response
})

export const setTransactionFor = createAsyncThunk('buyerJourney/setTransactionFor', async ({type, amount, account }, {rejectWithValue}) => {
  try {
    const res = await setTransaction({
      type,
      amount,
      account
    })
   return res
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const moneyManagSlice = createSlice({
  name: "moneyManag",
  initialState: {
    data: [],
    balance: 0,
    pendingBalance: 0,
    preview: {},
    steps: 1,
    amount: '',
    error: false,
    transaction: {type: '', status: ''}

  },
  reducers: {
    handleError: (state, action) => {
      state.error = action.payload
    },
    handleAcount: (state, action) => {
      if (action.payload === null) {
        state.preview = null
      }
      state.preview  = state.data.find((item) => item.account_id === Number(action.payload))
    },
    handleSteps: (state, action) => {
      state.steps = action.payload
    },
    handleAmount: (state, action) => {
      state.amount = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(setTransactionFor.fulfilled, (state, action) => {

     state.transaction = {type: action.payload.type, status: action.payload.status}
    })
    .addCase(getDataCards.fulfilled, (state, action) => {
      state.data = action.payload
     })
     .addCase(getBalanceFor.fulfilled, (state, action) => {
      state.balance = action.payload.balance
      state.pendingBalance = action.payload.pendingBalance
     })
  }
})

export const { handleAcount, handleSteps, handleAmount, handleError} = moneyManagSlice.actions

export default moneyManagSlice.reducer
