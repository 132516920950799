// store.js
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { securityProfileReducer } from './profile/security/index'
import { guaranteeReducer } from './profile/guarantee/index'
import { adminMarketReducer } from './admin/market/index'

const store = configureStore({
  reducer: {
    ...rootReducer,
    securityProfile: securityProfileReducer,
    guarantee: guaranteeReducer,
    adminMarket: adminMarketReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export { store }