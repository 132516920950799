import { axiosService } from '@services/axios'
import {MORPHEUS_URL} from '@src/config'

//const yearInit = new Date(new Date().getFullYear(), 0, 1)

export const getPackageDetails = async (params) => {
  const {
    paq_id,
    filters: {
      login = ''
    },
    page = 1,
    perPage = 10
  } = params
  try {
 
    const builtParams = {
      
    }
    if (paq_id) builtParams['paq_id'] = paq_id
    if (login) builtParams['login'] = login
    if (page && perPage) builtParams['skip'] = (page - 1) * perPage
    if (perPage) builtParams['limit'] = perPage

    const url = `${MORPHEUS_URL}/admin/getPackagesDetail`
    const ret = await axiosService.get(url, {params: builtParams})
    const response = ret.data
    
    const allData = (response?.all_data) ?? []
    const paginated = (response?.data) ?? []
    const compiledResponse = {
      data: paginated ?? allData,
      allData,
      params,
      totalPages: allData.length
    }

    return compiledResponse
  } catch (err) {
    return []
  }
}

export const getUsers = async () => {
  try {
    const url = `${MORPHEUS_URL}/admin/list-all-users`
    const response = await axiosService.get(url)
    return response.data 
  } catch (err) {
    return []
  }
}