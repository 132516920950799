import { MORPHEUS_URL } from '../../config'
import { axiosService } from '../axios'

export const fetchGraphicRescues = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/rescue/grafic_rescue`)
    return response.data
}

export const fetchRescues = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/rescue/investments`)
    return response.data
}

export const createRescues = async (data) => {
    const response = await axiosService.post(`${MORPHEUS_URL}/front/rescue/rescue_create`, data)
    return response.data
}

export const fetchInvestmentsMarket = async (filters) => {
    const objetoFiltrado = {}

    for (const [clave, valor] of Object.entries(filters)) {
        if (valor !== false && valor !== null && valor !== '') {
            objetoFiltrado[clave] = valor
        }
    }

    const response = await axiosService.get(`${MORPHEUS_URL}/front/rescue/market`, {
        params: {
            ...objetoFiltrado
        }
    })
    return response.data
}

