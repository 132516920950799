import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPackages } from '@services/admin/viewPackages'

export const getData = createAsyncThunk('viewPackages/get', async params => {
    const response = await getPackages(params)
    return response
})

export const viewPackagesSlice = createSlice({
    name: 'viewPackages',
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: { 
          filters: {
            date: ['', ''],
            usrLogin: ''
          },
          page: 1,
          perPage: 10
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default viewPackagesSlice.reducer
