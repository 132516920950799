import { axiosService } from '@services/axios'
import { MORPHEUS_URL } from '@src/config'

const veryOldDate = new Date(1900, 1, 1).toISOString().split("T")[0]

export const getMessages = async (params) => {
  const {
    usrLogin = '',
    sent = false
  } = params?.filters
  const init = params?.filters?.date?.[0]
  const end = params?.filters?.date?.[1]
  const {
    page = 1,
    perPage = 10
  } = params

  try {
    const builtParams = {
      date_init: init ?? veryOldDate,
      date_end: end ?? new Date().toISOString().split("T")[0]
    }
    if (usrLogin) builtParams['email'] = usrLogin
    if (page && perPage) builtParams['skip'] = (page - 1) * perPage
    if (perPage) builtParams['limit'] = perPage

    const url = `${MORPHEUS_URL}/admin/message/${sent ? 'sent' : 'recived'}`
    const ret = await axiosService.get(url, { params: builtParams })
    const response = ret.data
    const allData = (response?.all_data) ?? []
    const paginated = (response?.data)

    const unreadRet = await axiosService.get(`${MORPHEUS_URL}/admin/notification/not_read`)
    const unreadResponse = unreadRet.data

    const compiledResponse = {
      data: paginated ?? allData,
      allData,
      params,
      totalPages: allData.length,
      data_no_read: unreadResponse.data,
      total_no_read: unreadResponse.total_msg
    }
    return compiledResponse
  } catch (err) {
    return []
  }
}

export const getUnreadMessages = async () => {
  try {
    const unreadRet = await axiosService.get(`${MORPHEUS_URL}/admin/notification/not_read`)
    const unreadResponse = unreadRet.data
    const compiledResponse = {
      data_no_read: unreadResponse.data,
      total_no_read: unreadResponse.total_msg
    }

    return compiledResponse
  } catch (err) {
    return []
  }
}

export const getOneMessage = async ({ msgId, sent = true }) => {
  try {
    if (msgId !== undefined && msgId !== null) {
      const params = { msg_id: msgId }
      const url = `${MORPHEUS_URL}/admin/messsage/show/{id}`
      const response = await axiosService.get(url, { params })

      if (!sent) {
        const body = { read: true }
        await axiosService.put(`${MORPHEUS_URL}/admin/notify/read?mess_id=${msgId}`, body)
      }
      return response.data
    }

    return null
  } catch (err) {
    return null
  }
}

export const postMessages = async (params) => {
  const url = `${MORPHEUS_URL}/admin/message/create`
  const response = await axiosService.post(url, params)
  return response.data
}

export const deleteMessages = async (msgId) => {
  const url = `${MORPHEUS_URL}/admin/message/delete/{id}?mess_id=${msgId}`
  const response = await axiosService.delete(url)
  return response.data
}

export const getUsers = async () => {
  try {
    const url = `${MORPHEUS_URL}/admin/users/show`
    const response = await axiosService.get(url)
    return response.data
  } catch (err) {
    return []
  }
}