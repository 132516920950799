import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { updatePassword, updateEnableAccount, getRecentSessions } from '../../../services/profiles/security'

export const changePassword = createAsyncThunk('securityProfile/changePassword', async (data) => {
    const response = await updatePassword(data)
    return response.data
})

export const enableAccount = createAsyncThunk('securityProfile/enableAccount', async (data) => {
    const response = await updateEnableAccount(data)
    return response.data
})

export const getSessions = createAsyncThunk('securityProfile/getDevices', async () => {
    const response = await getRecentSessions()
    return response
})

const securityProfileSlice = createSlice({
    name: 'securityProfile',
    initialState: {
        data: [],
        params: {},
        sesiones: []
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(changePassword.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(enableAccount.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(getSessions.fulfilled, (state, action) => {
            state.sesiones = action.payload
        })
    }}
)


export const { actions: securityProfileActions, reducer: securityProfileReducer } = securityProfileSlice