import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getMessages, getUnreadMessages, getOneMessage, postMessages, deleteMessages } from '@services/admin/adminMessages'
import { getAllCBQueries } from '../../../services/communityBInquiries'
import { getAllQueries } from '../../../services/admin/manageQueries'

export const getUnread = createAsyncThunk('adminMessages/getUnread', async () => {

    const response = await getUnreadMessages()
    return response
})
export const getUnreadCB = createAsyncThunk('adminMessages/getUnreadCB', async () => {
    const response = await getAllCBQueries()
    return response
})
export const getUnreadContact = createAsyncThunk('adminMessages/getUnreadContact', async () => {
    const response = await getAllQueries()
    return response
})
export const getData = createAsyncThunk('adminMessages/get', async params => {
    const response = await getMessages(params)
    return response
})

export const getMessage = createAsyncThunk('adminMessages/getDetail', async params => {
    const response = await getOneMessage(params)
    return response
})

export const postMessage = createAsyncThunk('adminMessages/post', async (msgData, { dispatch, getState, rejectWithValue }) => {
    try {
        await postMessages(msgData)
        const ret = await dispatch(getData(getState().adminMessages.params))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const resetSent = createAsyncThunk('adminMessages/resetSent', async (sent, { dispatch, getState, rejectWithValue }) => {
    try {
        const ret = await dispatch(getData({
                ...getState().adminMessages.params,
                filters:{...getState().adminMessages.params.filters, sent}
            }))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const deleteMessage = createAsyncThunk('adminMessages/delete', async (id, { dispatch, getState, rejectWithValue }) => {
    try {
        await deleteMessages(id)
        const ret = await dispatch(getData(getState().adminMessages.params))
        return {ret}
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const adminMessagesSlice = createSlice({
    name: 'adminMessages',
    initialState: {
        modalOpen: null, /*Un solo tipo, unRead*/
        allData: [],
        data: [],
        data_no_read: [],
        total: 0,
        total_no_read: 0,
        total_no_read_cb: 0,
        total_no_read_contact: 0,
        msgDetail: null,
        params: {
          filters: {
            date: ['', ''],
            usrLogin: '',
            sent: true
          },
          page: 1,
          perPage: 10
        }
    },
    reducers: {
        clearAll: state => ({
            ...state,
            modalOpen: null
        }),
        continueToNotification: state => ({
            ...state,
            modalOpen: 'unRead'
        }),
        continueToAll: state => ({
            ...state,
            modalOpen: null
        }),
        clearMsgData: (state) =>  ({
            ...state, 
            msgDetail: null
        })
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.total = action.payload.totalPages
                state.total_no_read = action.payload.total_no_read
                state.data_no_read = action.payload.data_no_read
            })
            .addCase(getMessage.fulfilled, (state, action) => {
                state.msgDetail = action.payload
            })
            .addCase(getUnread.fulfilled, (state, action) => {
                state.total_no_read = action.payload.total_no_read
                state.data_no_read = action.payload.data_no_read
            })
            .addCase(getUnreadCB.fulfilled, (state, action) => {
                state.total_no_read_cb = action.payload.total_no_read
            })
            .addCase(getUnreadContact.fulfilled, (state, action) => {
                state.total_no_read_contact = action.payload.total_no_read
            })
    }
})

export const { clearAll, continueToAll, clearMsgData, continueToNotification } = adminMessagesSlice.actions
export default adminMessagesSlice.reducer