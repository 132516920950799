import { MORPHEUS_URL } from '../../../config'
import { axiosService } from '../../axios'

export const fetchGraphicData = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/grafic_rescue_admin`)
    return response.data
}

export const fetchRateProfiles = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/admin/rate_profiles`)
    return response.data
}

export const putStopRates = async (id) => {
    const url = id ? `${MORPHEUS_URL}/admin/rate_profiles/stop?id=${id}` : `${MORPHEUS_URL}/admin/rate_profiles/stop`
    const response = await axiosService.put(url)
    return response.data
}

export const putStartRates = async (id) => {
    const url = id ? `${MORPHEUS_URL}/admin/rate_profiles/start_restart?id=${id}` : `${MORPHEUS_URL}/admin/rate_profiles/start_restart`
    const response = await axiosService.put(url)
    return response.data
}

export const putRestoreRates = async (id) => {
    const url = id ? `${MORPHEUS_URL}/admin/rate_profiles/restore?id=${id}` : `${MORPHEUS_URL}/admin/rate_profiles/restore`
    const response = await axiosService.put(url)
    return response.data
}

export const putResetRates = async (id) => {
    const url = id ? `${MORPHEUS_URL}/admin/rate_profiles/reset?id=${id}` : `${MORPHEUS_URL}/admin/rate_profiles/reset`
    const response = await axiosService.put(url)
    return response.data
}


export const putUpdateRate = async (id, new_value) => {
    const response = await axiosService.put(`${MORPHEUS_URL}/admin/rate_profiles/modify?id=${id}&new_value=${new_value}`)
    return response.data
}

