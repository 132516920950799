import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getInvestments, getInvestmentsDetail, investmentsDetailFilters } from "../../services/myInvestments"

const currentDate = new Date()
  const year = currentDate.getFullYear()
  const day = currentDate.getDate().toString().padStart(2, '0')
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

export const fetchInvestments = createAsyncThunk('front/myInvestments', async () => {
  const response = await getInvestments(formattedDate)
  return response
})

export const fetchInvestmentsDetail = createAsyncThunk('front/investments',  async ({ type, skip, limit, mora }) => {
  const response = await getInvestmentsDetail(type, skip, limit, mora)
  return response
}
)

export const fetchInvestmentsDetailFilters = createAsyncThunk('front/investments/filters', async () => {
  const response = await investmentsDetailFilters()
  return response
  }
)

export const investmentSlice = createSlice({
  name: "investments", 
  initialState: {
    data: [],
    detail: [],
    filters: {
      profiles: [],
      terms: []
    },
    totalPages: 0
  },
  reducers: {
    clearLoans: (state) => {
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchInvestments.fulfilled, (state, action) => {
      state.data = action.payload
    })
    .addCase(fetchInvestmentsDetail.fulfilled, (state, action) => {
      const detailWithoutTotal = action.payload.filter(item => !item.hasOwnProperty('total'))
      
      const totalPages = action.payload.find(item => item.hasOwnProperty('total'))?.total || 0

      state.detail = detailWithoutTotal
      state.totalPages = totalPages
    })
      .addCase(fetchInvestmentsDetailFilters.fulfilled, (state, action) => {
        const profiles = action.payload.filter(item => item.perfil).map(item => item.perfil)
        const terms = action.payload.filter(item => item.plazo).map(item => item.plazo)
        state.filters.profiles = profiles
        state.filters.terms = terms
      })
  }
})
export const { clearInvestments } = investmentSlice.actions
export default investmentSlice.reducer