// ** Axios Imports
import { axiosService } from "@services/axios"
import { MORPHEUS_URL } from "../../../config"

export const getProfiles = async () => {
  const response = await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/`)
  return {
    data: response.data.data,
    allData: response.data.all_data,
    total: response.data.total_records
  }
}

export const getProfilesBy = async (filters) => {
  const response = await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/`, {
    params: {
      skip: ((filters.skip - 1) * filters.limit) || 0,
      limit: filters.limit || 0,
      ...(!!filters.email ? { email: filters.email } : {}),
      ...(filters.profile_type === null ? {} : { profile_type: filters.profile_type }),
      ...(filters.state_ === null ? {} : { state_: filters.state_ })
    }
  })
  return {
    data: response.data.data,
    allData: response.data.all_data,
    total: response.data.total_records
  }
}

export const getPhysicalProfile = async (id) => {
  const response = await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/physical_profile?id=${id}`)
  return {
    profile: response.data.data_pf[0],
    bankAccounts: response.data.data_bank,
    authorized: response.data.data_aut
  }
}

export const getJuridicalProfile = async (id) => {
  const response = await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/juridical_profile?id=${id}`)
  return {
    profile: response.data.data_pj[0],
    bankAccounts: response.data.data_bank,
    representatives: response.data.data_rep
  }
}

export const validatePProfile = async (validations) => {
  const id = validations.id
  const creditProfile = validations.creditProfile
  const maxAmmount = validations.maxAmmount
  const response = await axiosService.put(`${MORPHEUS_URL}/admin/verify_users/physical_profile?id=${id}&credit_profile=${creditProfile}&max_ammount=${maxAmmount}`,
    validations.files
  )
  return response
}

export const validateJProfile = async (validations) => {
  const id = validations.id
  const creditProfile = validations.creditProfile
  const maxAmmount = validations.maxAmmount
  const response = await axiosService.put(`${MORPHEUS_URL}/admin/verify_users/juridical_person?id=${id}&credit_profile=${creditProfile}&max_ammount=${maxAmmount}`,
    validations.files)
  return response
}

export const validateRepresentative = async (validations) => {
  const id = validations.id
  const repId = validations.repId
  const response = await axiosService.put(`${MORPHEUS_URL}/admin/verify_users/juridical_person/representative?user_id=${id}&rep_id=${repId}`,
    validations.files)
  return response
}

export const validateAuthorized = async (validations) => {
  const id = validations.id
  const aut_id = validations.aut_id
  const response = await axiosService.put(`${MORPHEUS_URL}/admin/verify_users/physical_profile/authorized?user_id=${id}&aut_id=${aut_id}`,
    validations.files)
  return response
}
export const getFile = async (id, profile, filename) => {
  await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/download/${id}/${filename}?profile_type=${profile}`,
    { responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', response.headers.filename) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
}

export const getRepFile = async (id, repId, filename) => {
  await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/download/representative/${id}/${repId}/${filename}`,
    { responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', response.headers.filename) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
}
export const getAutFile = async (id, aut_id, filename) => {
  await axiosService.get(`${MORPHEUS_URL}/admin/verify_users/download/authorized/${id}/${aut_id}/${filename}`,
    { responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', response.headers.filename) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
}