// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}
const initialCookieExpirationDate = () => {
  const cookie = window.localStorage.getItem('cookieExpirationDate')
  return cookie ? new Date(JSON.parse(cookie)) : null
}

const cookies = new Cookies()

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: initialUser(),
    profile:'',
    role: '',
    cookieExpirationDate: initialCookieExpirationDate(),
    isLoggingIn: false,
    isLoggin: false
  },
  reducers: {
    handleLogin: (state, action) => {
      cookies.set('valid', action.payload.valid, { path: '/' })
      state.userData = action.payload
      state.profile = action.payload.profile
      state.role = action.payload.role
      const cookieExpirationDate = new Date(new Date().getTime() + (1000 * 60 * 60))
      state.cookieExpirationDate = cookieExpirationDate
      localStorage.setItem('cookieExpirationDate', JSON.stringify(cookieExpirationDate.toISOString()))
      localStorage.setItem('userData', JSON.stringify(action.payload))
    },
    handleLogout: state => {
      state.userData = {}
      state.role = ''
      state.cookieExpirationDate = null
      localStorage.removeItem('userData')
      localStorage.removeItem('cookieExpirationDate')
      cookies.remove('valid', { path: '/' })
    },
    changeProfileType: (state, action) => {
      state.profile = action.payload
    },
    handleLoginPending: (state, action) => {
      state.isLoggingIn = action.payload
    }
  }
})


export const { handleLogin, handleLogout, changeProfileType, handleLoginPending } = authSlice.actions

export default authSlice.reducer
