import {MORPHEUS_URL} from '../../config'
import {axiosService} from '../axios'

export const getInvestments = async (fecha_i) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/dashboard/myInvestment?fecha_i=${fecha_i}`)
    return response.data
}

export const getSelectDeadlines = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/transaction/plazos`) 
    return response.data
}

export const getSelectCreditP = async (plazo) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/transaction/credit_profiles?plazo=${plazo}`)
    return response.data
}

export const getInvestmentsDetail = async (type, skip, limit, mora) => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/dashboard/detail-account?type=${type}&skip=${skip}&limit=${limit}&mora=${mora}`)
    return response.data
}

export const investmentsDetailFilters = async () => {
    const response = await axiosService.get(`${MORPHEUS_URL}/front/dashboard/detail-account-filter`)
    return response.data
}