import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchGraphicData, fetchRateProfiles, putStopRates, putStartRates,
    putRestoreRates, putResetRates, putUpdateRate
 } from "../../../services/admin/market"

export const getGraphicData = createAsyncThunk('adminMarket', async () => {
    const response = await fetchGraphicData()
    return response
})

export const getRateProfiles = createAsyncThunk('adminMarket/getRateProfiles', async () => {
    const response = await fetchRateProfiles()
    return response
})


export const stopRates = createAsyncThunk('adminMarket/stopRates', async (id, { dispatch, getState}) => {
    await putStopRates(id)
    const response = await dispatch(getRateProfiles(getState().adminMarket.rateProfiles))
    return response
})

export const startRates = createAsyncThunk('adminMarket/startRates', async (id, { dispatch, getState}) => {
    await putStartRates(id)
    const response = await dispatch(getRateProfiles(getState().adminMarket.rateProfiles))
    return response
})


export const restoreRates = createAsyncThunk('adminMarket/restoreRates', async (id, { dispatch, getState}) => {
    await putRestoreRates(id)
    const response = await dispatch(getRateProfiles(getState().adminMarket.rateProfiles))
    return response
})

export const resetRates = createAsyncThunk('adminMarket/resetRates', async (id, { dispatch, getState}) => {
    await putResetRates(id)
    const response = await dispatch(getRateProfiles(getState().adminMarket.rateProfiles))
    return response
})


export const updateRate = createAsyncThunk('adminMarket/updateRate', async (data, { dispatch, getState }) => {
    await putUpdateRate(data.id, data.new_value)
    const response = await dispatch(getRateProfiles(getState().adminMarket.rateProfiles))
    return response
})

export const AdminMarketSlice = createSlice({
    name: "adminMarket",
    initialState: {
        allData: [],
        data: [],
        total: 0,
        params: {},
        rateProfiles: {} 
    },
    reducers: {}, 
    extraReducers: builder => {
        builder
        .addCase(getGraphicData.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        .addCase(getRateProfiles.fulfilled, (state, action) => {
            state.rateProfiles = action.payload 
        })
        .addCase(stopRates.fulfilled, (state, action) => {
            state.rateProfiles = action.payload
        })
        .addCase(startRates.fulfilled, (state, action) => {
            state.rateProfiles = action.payload
        })

    }
})

export const { reducer: adminMarketReducer } = AdminMarketSlice
