import { axiosService } from "../axios"
import { MORPHEUS_URL } from "../../config"

const endpoint = {
    'variables base': 'basicVariables',
    'tasa bancos': 'bankRate',
    'probabilidad de incumplimiento': 'probability_default/'
}

export const getVariablesManagment = async (origin) => {
    const url = `${MORPHEUS_URL}/admin/${endpoint[origin]}`
        const response = await axiosService.get(url)
        return {
            data: response.data,
            totalPages: response.data.total_page,
            origin
        }
}

export const postVariablesManagment = async ({data, to}) => {
    const url = `${MORPHEUS_URL}/admin/${endpoint[to]}`
        const response = await axiosService.post(url, data)
        return response.data
}