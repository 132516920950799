/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getLoans, getInvestments, createPackage } from '@services/admin/manageRequests'

export const getInvestmentsData = createAsyncThunk('manageRequests/getInvestmentsData', async params => {
    const response = await getInvestments(params)
    return response
})
export const getLoansData = createAsyncThunk('manageRequests/getLoansData', async params => {
    const response = await getLoans(params)
    return response
})

export const createInvestmentPackage = createAsyncThunk('manageRequests/createInvestmentPackage', async (requests, { dispatch, getState, rejectWithValue }) => {
    try {
        await createPackage(requests)
        await dispatch(getInvestmentsData(getState().manageRequests.paramsInvestments))
        await dispatch(getLoansData(getState().manageRequests.paramsLoans))
        return requests
    } catch (err) {
        return rejectWithValue(err?.response?.data?.detail)
    }
})

export const ManageRequestsSlice = createSlice({
    name: 'manageRequests',
    initialState: {
        allDataInvestments: [],
        allDataLoans: [],
        investments: [],
        loans: [],
        totalLoans: 0,
        totalInvestments: 0,
        paramsLoans: {},
        paramsInvestments: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getLoansData.fulfilled, (state, action) => {
                state.loans = action.payload.data
                state.allDataLoans = action.payload.allData
                state.paramsLoans = action.payload.params
                state.totalLoans = action.payload.total
            })
            .addCase(getInvestmentsData.fulfilled, (state, action) => {
                state.investments = action.payload.data
                state.allDataInvestments = action.payload.allData
                state.paramsInvestments = action.payload.params
                state.totalInvestments = action.payload.total
            })
    }
})

export default ManageRequestsSlice.reducer